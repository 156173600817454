<template>
  <div>
    <c-table
      ref="checkTable"
      :title="title"
      :columns="gridColumns"
      :data="review.checks"
      :gridHeight="gridHeight"
      selection="multiple"
      rowKey="mdmChemReviewCheckId"
      :columnSetting="false"
      :filtering="false"
      :usePaging="false"
      :hideBottom="true"
      :editable="editable&&!disabled"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="제외" v-if="editable&&!disabled" icon="remove" @btnClicked="addRemove" />
          <c-btn label="추가" v-if="editable&&!disabled" icon="add" @btnClicked="addCheck" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='process'">
          <q-btn-group v-if="checkEditable" outline >
            <template>
              <c-btn
                v-if="props.row.mdmChemReviewCheckStepCd==='CRC0000005'"
                label="검토완료"
                icon="check" 
                @btnClicked="checkComplete(props)" />
              <c-btn 
                v-else-if="props.row.mdmChemReviewCheckStepCd==='CRC0000010'"
                label="검토취소"
                icon="cancel" 
                @btnClicked="checkCancel(props)" />
            </template>
          </q-btn-group>
          <template v-if="props.row.reviewDt">
            <div>
              검토일시 :
              <font class="text-primary" style="font-size:1.1em;font-weight:800;">
                {{props.row.reviewDt}}
              </font>
            </div>
          </template>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-review-check',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',
      }),
    },
    review: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',  // 사전검토 일련번호
        reviewRequestDate: '',  // 검토요청일
        plantCds: '',  // 사업장코드
        materialCd: '',  // 자재코드
        materialName: '',  // 자재명(KOR)
        materialNameEn: '',  // 자재명(ENG)
        deliveryVendorCd: null,  // 납품업체 코드
        deliveryVendorName: '',  // 납품업체명
        mfgVendorCd: null,  // 제조업체 코드
        mfgVendorName: '',  // 제조업체명
        mdmChemReviewStepCd: null,  // 사전검토 진행상태
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        hsNo: '',  // HS번호
        specificGravity: '',  // 비중
        casNo: '',  // CAS No.
        serialNo: '',  // 고유번호(유독물번호or고시번호)
        keNo: '',  // KE 번호
        psmFlag: 'N',  // PSM 대상 여부
        mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        returnRemark: '',  // 반려사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        subs: [],
        subsRegul: [],
        prodReguls: [],
        checks: [],
        deleteChecks: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnEditable: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      grid: {
        defaultColumns: [
          {
            name: 'userName',
            field: 'userName',
            label: '검토자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
      },
      checkCompleteUrl: '',
      checkCancelUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    title() {
      let _text = '검토 결과 목록'
      if (this.review.mdmChemReviewStepCd === 'MCR0000005') {
        _text = '검토자 목록'
      }
      return _text;
    },
    gridHeight() {
      let _margin = 600;
      if (this.btnEditable) {
        _margin += 50;
      }
      return String(this.contentHeight - _margin) + 'px';
    },
    gridColumns() {
      /**
       * 작성중인 단계에서 분야 col은 disableTarget에 값을 주지 않게 처리
       */
      let _col = this.$_.cloneDeep(this.grid.defaultColumns);
      if (this.review.mdmChemReviewStepCd === 'MCR0000001') {
        _col = this.$_.concat(_col, [
          {
            name: 'mdmChemReviewClassName',
            field: 'mdmChemReviewClassName',
            label: '분야',
            align: 'left',
            style: 'width:120px',
            sortable: false,
            type: 'text',
            required: true,
          },
        ]);
      } else {
        _col = this.$_.concat(_col, [
          {
            name: 'mdmChemReviewClassName',
            field: 'mdmChemReviewClassName',
            label: '분야',
            align: 'left',
            style: 'width:120px',
            sortable: false,
            type: 'text',
            required: true,
            disableTarget: 'mdmChemReviewCheckStepCd',
            disableCon: 'CRC0000005',
          },
        ]);
      }
      if (this.review.mdmChemReviewStepCd !== 'MCR0000001') {
        _col = this.$_.concat(_col, [
          {
            name: 'mdmChemReviewResultCd',
            field: 'mdmChemReviewResultCd',
            label: '검토결과',
            align: 'center',
            style: 'width:90px',
            sortable: false,
            type: 'radio',
            codeGroupCd: 'MDM_CHEM_REVIEW_RESULT_CD',
            disableTarget: 'mdmChemReviewCheckStepCd',
            disableCon: 'CRC0000005',
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'left',
            style: 'width:400px',
            sortable: false,
            type: 'textarea',
            disableTarget: 'mdmChemReviewCheckStepCd',
            disableCon: 'CRC0000005',
          },
          {
            name: 'process',
            field: 'process',
            label: '처리',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'custom',
          }
        ]);
      }
      return _col;
    },
    checkEditable() {
      return this.editable
        && this.review.mdmChemReviewStepCd === 'MCR0000005'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.checkCompleteUrl = transactionConfig.mdm.mam.review.check.complete.url;
      this.checkCancelUrl = transactionConfig.mdm.mam.review.check.cancel.url;
      // code setting
      // list setting
    },  
    addCheck() {
      this.popupOptions.title = '사용자 검색'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      // this.popupOptions.target = () => import(`${'@/pages/mdm/mam/review/chemReviewCheckerPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (!this.review.checks) this.review.checks = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.review.checks, { userId: item.userId }) === -1) {
            this.review.checks.splice(0, 0, {
              mdmChemReviewId: this.popupParam.mdmChemReviewId,
              mdmChemReviewCheckId: uid(),
              mdmChemReviewClassName: '',
              userId: item.userId,
              userName: item.userName,
              mdmChemReviewResultCd: 'MCRR000005', // 적합
              mdmChemReviewCheckStepCd: 'CRC0000001', // 준비중
              remarks: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
        });
      }
    },
    addRemove() {
      let selectData = this.$refs['checkTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '제외하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, item => {
              if (!this.review.deleteChecks) this.review.deleteChecks = [];
              if (item.editFlag !== 'C' && this.$_.findIndex(this.review.deleteChecks, { mdmChemReviewCheckId: item.mdmChemReviewCheckId })) {
                this.review.deleteChecks.push(item)
              }
              this.review.checks = this.$_.reject(this.review.checks, item);
            })
            this.$refs['checkTable'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    checkComplete(data) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '검토완료하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          data.row.chgUserId = this.$store.getters.user.userId;

          this.$http.url = this.checkCompleteUrl
          this.$http.type = 'PUT';
          this.$http.param = data.row
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$set(this.review.checks, [data.rowIndex], _result.data)
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    checkCancel(data) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '검토취소하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          data.row.chgUserId = this.$store.getters.user.userId;

          this.$http.url = this.checkCancelUrl
          this.$http.type = 'PUT';
          this.$http.param = data.row
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$set(this.review.checks, [data.rowIndex], _result.data)
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>